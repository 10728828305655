/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import libraryEmploymentImage from '../images/employment.jpg';
import libraryEmploymentImageFull from '../images/employment-full.jpg';

import Icon from '../images/employment-law.svg?react';
import bubbleImage from '../images/employment-law-bubble.png';

const TITLE = 'Employment law';

const employmentLaw: LibraryCategory = {
  name: TITLE,
  title: 'Introduction to Employment Law in Ireland',
  image: libraryEmploymentImage,
  previewImage: libraryEmploymentImageFull,
  shortDescription: 'Fair treatment for both employers and employees.',
  description:
    `Employment law in Ireland is the set of laws that govern the employment relationship between an employer and an employee. It ensures the employee is treated fairly, has legal rights in the workplace, and complies with legal requirements. Owing to the dynamic nature of work, including remote and flexible work, employers and employees need to know the employment rules to avoid legal conflicts and have a good working relationship.
    `,
  cases: cases.filter((c) => c.category.toLocaleLowerCase() === TITLE.toLocaleLowerCase()),
  Icon,
  bubbleImage,
  bodyText: [
    {
      header: 'Key legislation to consider',
      body: `
        <ul>
          <li><b>The Employment Equality Acts 1998-2021</b> prohibits discrimination based on gender, age, disability, race and other characteristics.</li>
          <li><b>The Unfair Dismissals Act 1977-2015</b> protects against unfair dismissal and sets out the fair procedures employers must follow when terminating employment.</li>
          <li><b>The Organisation of Working Time Act 1997</b> is the legislation on working time, rest breaks, and holiday leave to improve working conditions.</li>
          <li><b>The Minimum Wage Acts 2000-2018</b> fix the national minimum wage and other related matters.</li>
          <li><b>The Safety, Health and Welfare at Work Act 2005</b> obliges employers to provide employees with a safe and healthy working environment.</li>
        </ul>`,
    },
    {
      header: 'Common questions asked about Employment Law in Ireland',
      body: `<h3>What is considered a fair dismissal?</h3>
      A dismissal is deemed fair to proper reasons such as capability, conduct, redundancy, or other similar reasons, and the proper procedures, including notice and the right of appeal, have been followed.
      <br/>
      <br/>
      <h3>What effects does the new law have on employment and new work arrangements?</h3>
      Since the increase of remote and flexible work, employers must update their workplace policies to conform to employment laws, especially on issues of working hours, health and safety and employees’ rights.
      <br/>
      <br/>
      <h3>What are the available dispute resolution options?</h3>
      Industrial relations disputes can be settled through workplace grievance procedures, mediation, the Workplace Relations Commission (WRC), and, if failed,  the Labour Court or other courts.
      `
    }
  ],
};

export default employmentLaw;
