/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import libraryCommercialLawImage from '../images/commercial-law-full-uk.jpg';
import libraryCommercialLawImageFull from '../images/commercial-law-full-uk.jpg';

import Icon from '../images/pound.svg?react';
import bubbleImage from '../images/commercial-law-bubble-uk.png';

const TITLE = 'Commercial Law';

const commercialLaw: LibraryCategory = {
  name: TITLE,
  title: 'Introduction to Commercial Law in the UK',
  image: libraryCommercialLawImage,
  previewImage: libraryCommercialLawImageFull,
  shortDescription: 'Commercial law covers the legal framework for business transactions and agreements.',
  description:
    'Commercial law dictates how businesses are created and operated. It includes incorporation and partnership, structure and regulation, company formation and management, partnerships, contractual and consumer rights, and dispute resolution in a business context.',
  cases: cases.filter((c) => c.category.toLocaleLowerCase() === TITLE.toLocaleLowerCase()),
  Icon,
  bubbleImage,
  bodyText: [
    {
      header: 'Key legislation to consider',
      body: `
        <ul>
          <li><b>The Companies Act</b> outlines how companies incorporate, govern, dissolve, and defines directors' duties.</li>
          <li><b>The Consumer Rights Act</b> sets rules for fair and accurate sales. It ensures that goods and services match their descriptions and are suitable for their intended use.</li>
          <li><b>The Partnership Acts and the LLP (Legal Liability Partnerships) Act</b> explain how partnerships and limited liability partnerships form and manage themselves.</li>
          <li><b>The Financial Services and Markets Act</b> regulates certain financial businesses under the oversight of the Financial Conduct Authority.</li>
        </ul>`,
    },
    {
      header: 'Common questions asked about Commercial Law in the UK',
      body: `<h3>Which business structure should I choose?</h3>
      A sole trader arrangement is straightforward but does not provide limited liability. Partnerships or LLPs involve shared decision-making and shared liability among partners. Limited companies are distinct legal entities that offer limited liability but require more formalities and ongoing compliance.
      <br/>
      <br/>
      <h3>What should a commercial contract include?</h3>
      Key elements typically cover the scope of work or services, payment terms, liability limitations, confidentiality provisions, and termination clauses. Contracts also specify which legal system and jurisdiction apply if disputes arise.
      <br/>
      <br/>
      <h3>What consumer protection laws apply to my business?</h3>
      If you sell to consumers, you must follow the Consumer Rights Act. This law ensures that goods and services match their descriptions and are of good quality.  Distance selling rules also apply when transactions occur online or by phone, granting consumers cancellation periods and other protections.
      <br/>
      <br/>
      <h3>How can I resolve commercial disputes out of court?</h3>
      Early negotiation often helps preserve ongoing business relationships. Both sides may work together with a neutral mediator to reach an agreement without proceeding to a court trial.
      `
    }
  ],
};

export default commercialLaw;
