/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import libraryLitigationImage from '../images/litigation-law-full.jpg';
import libraryLitigationImageFull from '../images/litigation-law-full.jpg';
import Icon from '../images/litigation-law.svg?react';
import bubbleImage from '../images/litigation-law-bubble.png';

const TITLE = 'Litigation';

const litigationLaw: LibraryCategory = {
  name: TITLE,
  title: 'An Overview of Litigation in Ireland',
  image: libraryLitigationImage,
  previewImage: libraryLitigationImageFull,
  shortDescription: 'Litigation is resolving disputes through courts and legal proceedings.',
  description:
    'Litigation in Ireland resolves civil disputes between individuals, businesses, or other organisations through the courts. Some of the usual claims include breach of contract, negligence, defamation, and personal injury. Ireland has a standard law system, and procedural rules in legislation and court rules govern litigation.',
  cases: cases.filter((c) => c.category.toLocaleLowerCase() === TITLE.toLocaleLowerCase()),
  Icon,
  bubbleImage,
  bodyText: [
    {
      header: 'Key legislation to consider',
      body: `
        <ul>
          <li><b>The Rules of the Superior Courts</b> cover the High Court, Court of Appeal and Supreme Court procedures.</li>
          <li><b>Circuit Court Rules & District Court Rules</b> address the procedures for civil litigation in the lower courts.</li>
          <li><b>The Statute of Limitations Act 1957 (as amended)</b> – provides the clock on when and when not to file various types of claims.</li>
          <li><b>The Defamation Act 2009</b>  covers legal actions relating to defamation, libel and slander.</li>
        </ul>`,
    },
    {
      header: 'Common questions about Litigation Law in Ireland',
      body: `<h3>How do I begin a court claim?</h3>
      Before going to court, parties should try to resolve the dispute through negotiation or mediation and send a formal letter of claim. If unresolved, a claim is filed in the appropriate court—District, Circuit, or High Court—based on the claim's value and complexity. The defendant is then served and must respond within a set timeframe.
      <br/>
      <br/>
      <h3>Am I required to attempt mediation or ADR?</h3>
      Under the Mediation Act 2017, parties are encouraged to try mediation before going to court. Solicitors must advise clients on its benefits, and refusing ADR without a valid reason may affect cost awards.
      <br/>
      <br/>
      <h3>What are the time limits for bringing a claim?</h3>
      The Statute of Limitations Act 1957 sets different time limits. Contractual and negligence claims (excluding personal injury) must be filed within six years. Personal injury claims must be brought within two years of injury or awareness. Defamation claims must be filed within one year of publication.
      <br/>
      <br/>
      <h3>How are legal costs allocated if I win or lose?</h3>
      Generally, the losing party pays the winner’s legal costs, but the court can make exceptions. Refusing mediation or ADR when reasonable can affect cost awards, even if the party wins.
      <br/>
      <br/>
      <h3>What evidence do I need for my case?</h3>
      Key evidence includes contracts, emails, financial records, and correspondence. Personal injury claims require medical reports. Witness statements help support claims, and expert reports may be needed for complex cases.
      `
    }
  ],
};

export default litigationLaw;
