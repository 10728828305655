import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Header } from '../../../components/header';
import { Footer } from '../../../components/footer';
import { LibraryHero } from '../hero';
import { listOfLibraryCountries } from '../assets';
import style from './style.module.less';
import { CategoryCards } from '../category-card';
import { NeedHelpCard } from '../../../components/need-help';
import { Link, useParams } from 'react-router-dom';
import { LanguageCodes } from '../../../constants';
import { convertToKebabCase } from '../../../utils/case-conversion';
import { getLibraryPathWithCountry } from '../../../utils/get-library-path-with-country';
import ArrowRightIcon from '../../../assets/icons/arrow-right.svg?react';
import { SupportedLanguages } from '../../../i18n';

export const LibraryHome = () => {
  // get the :languageCode from the url
  const { languageCode } = useParams<{ languageCode: LanguageCodes }>();
  const { t } = useTranslation();

  const currentCountry = useMemo(
    () =>
      listOfLibraryCountries.find(
        (country) => country.language === languageCode
      ),
    [languageCode]
  );

  const currentCountryName = useMemo(
    () =>
      SupportedLanguages.find(
        (country) => country.key === languageCode
      )?.nativeName,
    [languageCode]
  );

  const currentCountryContent = useMemo(
    () =>
      listOfLibraryCountries.filter(
        (country) => country.content.length > 0
      ),
    []
  );

  return (
    <>
      <Helmet>
        <title>{t('pages.library')}</title>
      </Helmet>
      <Header />
      <div className={style.menuDivider} />
      <LibraryHero
        breadcrumbs={[
          {
            name: t('pages.library'),
            url: `${getLibraryPathWithCountry()}`
          },
          {
            name: `${currentCountryName}`,
          },
        ]}
        description={t('library.home.heading')}
      />
      <div className={style.container}>
        <div className={style.descriptionPadding}>
          {currentCountry ? (
            <>
              <div className={style.descriptionContainer}>
                {t('library.home.description')}
              </div>
              <CategoryCards
                categories={currentCountry.content}
                classNames={{ containerClassName: style.categoriesContainer }}
              />
            </>
          ) : (
            <>
              <div className={style.notSupportedContainer}>
                <div
                  className={style.notSupportedText}
                  dangerouslySetInnerHTML={{
                    __html: t('library.country-not-supported'),
                  }}
                />
              </div>
            </>
          )}
        </div>
        <div className={style.countryListContainer}>
          <div className={style.countryListContentContainer}>
            <div className={style.countryListTitle}>
              {t('library.country-selector-title')}
            </div>
            <div
              className={style.countryListDescription}
              dangerouslySetInnerHTML={{
                __html: t('library.country-selector-description'),
              }}
            />

            <div className={style.countryLists}>
              {currentCountryContent.map((country) => (
                <div className={style.countryList} key={country.country}>
                  <Link
                    to={`/${country.language}/library`}
                    className={style.countryListName}
                  >
                    {country.country}
                    <ArrowRightIcon className={style.categoryListArrow} />
                  </Link>
                  <div className={style.countryCategories}>
                    {country.content.map((category) => (
                      <Link
                        to={`/${country.language}/library/${convertToKebabCase(
                          category.name
                        )}`}
                        className={style.countryCategory}
                        key={category.name}
                      >
                        {category.name}
                      </Link>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={style.needHelpPadding}>
          <NeedHelpCard />
        </div>
      </div>
      <Footer className={style.noFooterMargin} />
    </>
  );
};
