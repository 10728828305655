/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import libraryCommercialLawImage from '../images/commercial-law-full-ie.jpg';
import libraryCommercialLawImageFull from '../images/commercial-law-full-ie.jpg';

import Icon from '../images/euro.svg?react';
import bubbleImage from '../images/commercial-law-bubble-ie.png';

const TITLE = 'Commercial Law';

const commercialLaw: LibraryCategory = {
  name: TITLE,
  title: 'Introduction to Commercial Law in Ireland',
  image: libraryCommercialLawImage,
  previewImage: libraryCommercialLawImageFull,
  shortDescription: 'Commercial law covers the legal framework for business transactions and agreements.',
  description:
    'Commercial law in the Republic of Ireland encompasses all legal aspects of business operations, including the formation and management of companies, contractual relationships, consumer protection, and dispute resolution. It aims to create a stable legal environment for commerce, protect consumer rights, and promote fair competition.',
  cases: cases.filter((c) => c.category.toLocaleLowerCase() === TITLE.toLocaleLowerCase()),
  Icon,
  bubbleImage,
  bodyText: [
    {
      header: 'Key legislation to consider',
      body: `
        <ul>
          <li><b>The Companies Act 2014</b> governs the incorporation, management, and obligations of companies in Ireland, including directors' duties and corporate governance rules.</li>
          <li><b>The Sale of Goods Act 1893 and the Sale of Goods and Supply of Services Act 1980</b> provide rules on the sale and supply of goods and services, including implied terms and consumer rights.</li>
          <li><b>The Consumer Protection Act 2007</b> ensures consumer rights and fair trading practices and enforces prohibitions on unfair commercial practices.</li>
          <li><b>The Competition Act 2002 (as amended)</b> regulates anti-competitive agreements, abuse of dominant position, and mergers.</li>
          <li><b>Contract Law (common law principles)</b> forms the basis of all contractual agreements, including offer, acceptance, consideration, and remedies for breach.</li>
        </ul>`,
    },
    {
      header: 'Common questions about Commercial Law in Ireland',
      body: `<h3>How do I set up a company?</h3>
      You can register a company through the Companies Registration Office (CRO). You must choose a company type (e.g., private limited company), submit a constitution, appoint directors and a company secretary, and pay the registration fee.
      <br/>
      <br/>
      <h3>What are my obligations as a company director?</h3>
      Directors are responsible for complying with the Companies Act 2014, acting in the company's best interests, maintaining proper accounting records, and filing annual returns on time with the CRO.
      <br/>
      <br/>
      <h3>How do I enforce a contract?</h3>
      Contracts can be enforced through negotiation, mediation, or legal proceedings. If an out-of-court resolution fails, you may initiate court action to seek damages or other remedies.
      <br/>
      <br/>
      <h3>What are my consumer protection rights?</h3>
      Consumers in Ireland are protected by legislation that requires goods and services to be of merchantable quality and fit for purpose. Unfair commercial practices, such as misleading advertising, are prohibited, and consumers may seek redress through the courts or relevant regulatory bodies.
      `
    }
  ],
};

export default commercialLaw;
