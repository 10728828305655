import React, { FC, useEffect, useMemo } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { LibraryCase } from './case';
import { LibraryHome } from './home';
import { LibraryCategory } from './category';
import { useTranslation } from 'react-i18next';
import { listOfLibraryCountries } from './assets';
import { CategoryDirectory } from './category-directory';
import { getI18n } from 'react-i18next';
// we want to import a list of folders to get the categories

export const Library: FC = () => {
  // if language is changed here, we want to redirect to home page

  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const userLocale = getI18n().language || navigator.language; // Use the user's locale or default to browser's language setting

  const country = useMemo(
    () =>
      listOfLibraryCountries.find(
        (country) => country.language === i18n.language
      ) ?? listOfLibraryCountries[0],
    [i18n.language]
  );
  // Returns "US", "FR", etc.

  return (
    <Routes>
      <Route path='/' element={<LibraryHome />} />
      <Route path='/:category' element={<LibraryCategory />} />
      <Route
        path='/:category/directory'
        element={<CategoryDirectory />}
      />
      <Route
        path='/:category/:caseName'
        element={<LibraryCase />}
      />
      {/* no match then we redirect to language country */}
      <Route path='*' element={<Navigate to={`/${userLocale}/library`} />} />
    </Routes>
  );
};
