/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import libraryEmploymentImage from '../images/employment.jpg';
import libraryEmploymentImageFull from '../images/employment-full.jpg';

import Icon from '../images/employment-law.svg?react';
import bubbleImage from '../images/employment-law-bubble.png';

const TITLE = 'Employment and labor law';

const employmentLaw: LibraryCategory = {
  name: TITLE,
  title: 'Introduction to Employment Law in Australia',
  image: libraryEmploymentImage,
  previewImage: libraryEmploymentImageFull,
  shortDescription: 'Fair treatment for both employers and employees.',
  description:
    'Employment relations are legal principles that regulate the employment relationship between employers and employees in Australia. They provide legal safeguards and ensure employers treat workers with dignity and respect. Due to the dynamic nature of work (such as remote work and flexible work arrangements), employers and employees need to understand and comply with the employment rules to avoid legal consequences.',
  directoryTitle: 'Employment and labor law case studies',
  cases: cases.filter((c) => c.category.toLocaleLowerCase() === TITLE.toLocaleLowerCase()),
  Icon,
  bubbleImage,
  bodyText: [
    {
      header: 'Key legislation to consider',
      body: `
        <ul>
          <li><b>The Fair Work Act 2009</b> defines the rights and duties of employers and employees. It also creates the Fair Work Commission.</li>
          <li>The <b>National Employment Standards (NES)</b> outlines minimum workplace conditions such as maximum weekly hours, leave entitlements, and notice periods.</li>
          <li>The <b>Workplace Health and Safety (WHS) Laws</b> regulate safe work practices, appropriate equipment, training, and employee consultation.</li>
          <li><b>State-specific legislation:</b> Certain states or territories may have additional workplace safety or industrial relations laws complementing federal regulations.</li>
        </ul>`,
    },
    {
      header: 'Common questions asked about Employment Law in Australia',
      body: `<h3>What is a fair dismissal?</h3>
      A dismissal is fair if there is a good reason related to the employee's behaviour or work. It is also fair if the right steps have been taken.
      <br/>
      <br/>
      <h3>How does this affect the entitlements of new work arrangements?</h3>
      Changes in work practices may require updates to policies. It is important to stay informed.
      <br/>
      <br/>
      <h3>What dispute resolution options are available?</h3>
      They are internal negotiations, mediation, arbitration by Fair Work Australia and legal action if all else fails.
      `
    }
  ],
};

export default employmentLaw;
