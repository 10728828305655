/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import Icon from '../images/criminal-law.svg?react';
import bubbleImage from '../images/criminal-law-bubble.png';
import criminalImage from '../images/criminal-law-full.jpg';
import criminalImageFull from '../images/criminal-law-full.jpg';

const TITLE = 'Criminal law';

const criminalLaw: LibraryCategory = {
  name: TITLE,
  title: 'Introduction to Criminal Law in Australia',
  image: criminalImage,
  previewImage: criminalImageFull,
  shortDescription:
    'Maintain order, protect individuals and property, and uphold justice.',
  description:
    'Criminal law in Australia defines what is illegal and what consequences follow for the offenders. Its primary purpose is to ensure order in society and to protect the society and individuals, including other citizens. Knowledge of criminal law is necessary to comprehend the justice system from the point of arrest to trial, sentencing and defence.',
  directoryTitle: 'Criminal law case studies',
  cases: cases.filter((c) => c.category.toLocaleLowerCase() === TITLE.toLocaleLowerCase()),
  Icon,
  bubbleImage,
  bodyText: [
    {
      header: 'Key legislation to consider',
      body: `
        <ul>
          <li>The Criminal Code Act (and associated statutes) define criminal offences and describe the consequences of such offences.</li>
          <li>State-specific criminal legislation deals with unique features of criminal law and enforcement.</li>
          <ul>
            <li>Procedural Acts outline the criminal proceedings and ensure that the accused's rights are secured during the trial.</li>
          </ul>
        </ul>`,
    },
    {
      header: 'Common questions asked about Criminal Law in Australia',
      body: `<h3>What rights do I have if I am arrested?</h3>
      You are presumed innocent until proven guilty, and the prosecution has the burden of proof.
      <br/>
      <br/>
      <h3>Why is it essential to have a lawyer in criminal cases?</h3>
      It is crucial to have a good lawyer to protect your rights and ensure you get a fair trial.
      <br/>
      <br/>
      <h3>What other forms of punishment are available instead of imprisonment?</h3>
      Based on the case, penalties such as community service, probation or other penalties can be imposed.
      `
    }
  ],
};

export default criminalLaw;
