/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import bubbleImage from '../images/family-law-bubble.png';
import familyImage from '../images/family.jpg';
import familyImageFull from '../images/family-full.jpg';
import Icon from '../images/family-law.svg?react';

const TITLE = 'Family law';

const familyLaw: LibraryCategory = {
  name: TITLE,
  title: 'Introduction to Family Law in Ireland',
  image: familyImage,
  previewImage: familyImageFull,
  shortDescription:
    'Navigating family law in Ireland can be complex and challenging.',
  description:
    'Irish family law provides for marriage, civil partnerships, divorce, and judicial separation. It also addresses cohabitation and child-related matters like parental responsibility and child support. The system protects family and individual interests while favouring peaceful conflict resolution and putting child welfare at the centre of decisions.',
  directoryTitle: 'Family law case studies',
  cases: cases.filter((c) => c.category.toLocaleLowerCase() === TITLE.toLocaleLowerCase()),
  Icon,
  bubbleImage,
  bodyText: [
    {
      header: 'Key legislation to consider',
      body: `
        <ul>
          <li><b>The Family Law Act 1995 & Family  Law (Divorce) Act 1996</b> regulate the legal processes for separation and divorce,  including financial settlements and arrangements for children. Guardianship of Infants Act 1964 (as amended) determines the parental roles for children including all matters related to guardianship and access and custody issues.</li>
          <li><b>The Civil Registration Act 2004</b> controls the civil registration of births, marriages, and deaths, with specific provisions expanded for civil partnerships and same-sex marriages after the initial act.</li>
          <li><b>The Child and Family Relationships Act  2015</b> reforms the laws concerning parentage and the care of children, including custody and access and guardianship responsibilities. It also includes additional provisions for assisted reproduction and surrogacy where applicable.</li>
          <li><b>The Domestic Violence  Act 2018</b> gives legal protection to victims of domestic violence; courts can make safety, barring and protection orders.</li>
        </ul>`,
    },
    {
      header: 'Common questions about Family Law in Ireland',
      body: `<h3>How do I get a divorce in  Ireland?</h3>
      To get a divorce, you must comply with specific prerequisites: Both spouses must maintain separate residences for at least two continuous years out of the three years, and there must be no prospect of reuniting while adequate provisions benefit both spouses and dependents.
      <br/>
      <br/>
      <h3>What is judicial separation?</h3>
      Judicial separation constitutes an official court-ordered separation between spouses through which they maintain their marital connection. This legal separation helps handle separated, marital issues regarding assets and child care responsibilities but fails to terminate marital status.
      <br/>
      <br/>
      <h3>How is child custody determined?</h3>
      The child's best interests serve as the top priority in court decisions. The stability of living arrangements and the capacity of each parent to meet the child's needs, along with the child's wishes (when capable of expressing them), form the basis of these evaluations. The courts typically award joint custody to fit parents.
      `
    }
  ],
};

export default familyLaw;
