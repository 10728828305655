import criminalLaw from './criminal_law';
import employmentLaw from './employment_law';
import familyLaw from './family_law';
import estatesAndProbates from './estates_and_probates';
import immigrationLaw from './immigration_law';
import litigationLaw from './litigation';
import realEstate from './real_estate';
import { LibraryCategory } from '../../../../types/library';
import directory from './directory';

const libraryAssets: LibraryCategory[] = [
  criminalLaw,
  employmentLaw,
  estatesAndProbates,
  familyLaw,
  immigrationLaw,
  litigationLaw,
  realEstate,
  directory,
];

export default libraryAssets;
export const australiaDescription =
  // eslint-disable-next-line @stylistic/js/max-len
  'We have curated some common example legal cases created by our users from common areas of law. Browse the categories for your area of interest.';
