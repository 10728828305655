import React, { FC, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import libraryAssets from '../assets';
import { convertToKebabCase } from '../../../utils/case-conversion';
import { Header } from '../../../components/header';
import { LibraryHero } from '../hero';
import { NeedHelpCard } from '../../../components/need-help';
import { Footer } from '../../../components/footer';
import style from './style.module.less';
import { 
  getLibraryPathWithCountry
} from '../../../utils/get-library-path-with-country';
import { Link } from 'react-router-dom';
import { LanguageCodes } from '../../../constants';
import { SupportedLanguages } from '../../../i18n';
import { listOfLibraryCountries } from '../assets';

export const CategoryDirectory: FC = () => {
  const { category } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // get the :country from the url
  const { languageCode } = useParams<{ languageCode: LanguageCodes }>();

  const currentCountryName = useMemo(
    () =>
      SupportedLanguages.find(
        (country) => country.key === languageCode
      )?.nativeName,
    [languageCode]
  );

  const currentCountry = useMemo(
    () =>
      listOfLibraryCountries.find(
        (country) => country.language === languageCode
      ),
    [languageCode]
  );

  const libraryCategory = libraryAssets.find(
    (c) => convertToKebabCase(c?.name) === category
  );

  const LISTS_PER_GROUP = 6;
  const CASES_PER_LIST = 10;
  const numLists = Math.ceil(libraryCategory.cases.length / CASES_PER_LIST);
  const casesLists = Array.from({ length: numLists }, (_, i) =>
    [...libraryCategory.cases]
      .sort((a, b) => a.name.localeCompare(b.name))
      .slice(i * CASES_PER_LIST, (i + 1) * CASES_PER_LIST)
  );

  const casesListGroups = Array.from(
    { length: Math.ceil(casesLists.length / LISTS_PER_GROUP) },
    (_, i) => casesLists.slice(i * LISTS_PER_GROUP, (i + 1) * LISTS_PER_GROUP)
  );
  
  useEffect(() => {
    // if no category or case found, redirect to library
    if (!libraryCategory) {
      navigate(getLibraryPathWithCountry('', true));
    }
  }, [libraryCategory, navigate]);

  useEffect(() => {
    // if not a supported language, redirect to library
    if (!currentCountry) {
      navigate(getLibraryPathWithCountry('', true));
    }
  }, [currentCountry, navigate]);

  if (!libraryCategory) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{t('pages.library')}</title>
      </Helmet>
      <Header />
      <div className={style.menuDivider} />
      <LibraryHero
        breadcrumbs={[
          {
            name: t('pages.library'),
            url: `${getLibraryPathWithCountry()}`,
          },
          {
            name: `${currentCountryName}`,
            url: `${getLibraryPathWithCountry('', true)}`,
          },
          {
            name: libraryCategory.name,
            url: getLibraryPathWithCountry(
              `/${convertToKebabCase(libraryCategory.name)}`,
              true
            ),
          },
          {
            name: 'Directory',
          },
        ]}
        description={libraryCategory.directoryTitle}
      />
      <div className={style.descriptionContainer}>
        <div
          className={style.description}
          dangerouslySetInnerHTML={{
            __html: libraryCategory.description,
          }}
        />
      </div>

      <div className={style.directoryContainer}>
        <div className={style.directoryContainerInner}>
          {casesListGroups.map((casesGroup, groupIndex) => (
            <div key={groupIndex}>
              <div className={style.directoryCases}>
                {casesGroup.map((casesList, listIndex) => (
                  <div className={style.categoryCasesList} key={listIndex}>
                    {casesList.map((caseItem) => (
                      <Link
                        to={`/${languageCode}/library/${convertToKebabCase(
                          libraryCategory.name
                        )}/${convertToKebabCase(caseItem.name)}`}
                        className={style.categoryCase}
                        key={convertToKebabCase(caseItem.id)}
                      >
                        {caseItem.name}
                      </Link>
                    ))}
                  </div>
                ))}
              </div>
              <NeedHelpCard
                classNames={{ container: style.needHelpContainer }}
              />
            </div>
          ))}
        </div>
      </div>

      <Footer className={style.noFooterMargin} />
    </>
  );
};
