/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import bubbleImage from '../images/immigration-law-bubble.png';
import immigrationLawImage from '../images/immigration-law-full.jpg';
import immigrationLawImageFull from '../images/immigration-law-full.jpg';
import Icon from '../images/immigration-law.svg?react';

const TITLE = 'Immigration law';

const immigrationLaw: LibraryCategory = {
  name: TITLE,
  title: 'Introduction to Immigration Law in Ireland',
  image: immigrationLawImage,
  previewImage: immigrationLawImageFull,
  shortDescription: 'Navigating family, sponsorships, visas, applications, and residency.',
  description:
    'The laws governing immigration to the Republic of Ireland define the procedures followed by non-Irish nationals when entering the State and staying or working there. This category includes the visa needs of individuals, the process of obtaining residence permits, the procedure for asylum seekers and refugees applying for protection, and the acquisition of citizenship. This approach aims to implement efficient migration strategies while safeguarding the rights of migrants and fulfilling global responsibilities.',
  cases: cases.filter(
    (c) => c.category.toLocaleLowerCase() === TITLE.toLocaleLowerCase()
  ),
  Icon,
  bubbleImage,
  bodyText: [
    {
      header: 'Key legislation to consider',
      body: `
        <ul>
          <li><b>The Immigration Act 2004</b> regulates when people can enter and stay in the State and the process for removing non-nationals.</li>
          <li><b>The International Protection Act 2015</b> builds the framework for asylum seekers and refugees to apply for protection and define their rights and entitlements during the process.</li>
          <li><b>The Irish Nationality and Citizenship Acts  1956–2021</b> define how Irish citizenship can be obtained through naturalisation, descent, and marriage while specifying the conditions for each.</li>
          <li><b>The Employment Permits Acts  2003–2014</b> ensures proper work authorisation for non-EEA nationals by establishing both General and Critical Skills Employment Permits.</li>
          <li><b>The European Communities (Free Movement of Persons) Regulations</b> allow EU, EEA and Swiss citizens, including their family members, to move and reside in Ireland.</li>
        </ul>`,
    },
    {
      header: 'Common questions about Immigration Law in Ireland',
      body: `<h3>Do I require a visa to enter or work in Ireland?</h3>
      Visa needs depend on your country of origin and intended stay in Ireland. Some of these individuals may require a visa, or it may be necessary to have permission to enter the country. Non-EEA nationals planning to seek employment in Ireland may need to obtain work permits.
      <br/>
      <br/>
      <h3>How can I apply for Irish citizenship?</h3>
      You can apply for naturalisation if you have resided in Ireland, have good character, and intend to continue living in the State. Other ways to obtain citizenship are through descent or marriage.
      <br/>
      <br/>
      <h3>What is international protection (asylum)?</h3>
      International protection is the legal process by which a person seeks asylum or subsidiary protection status. The International Protection Office reviews all applications under the International Protection Act 2015.
      <br/>
      <br/>
      <h3>How do you renew or change your immigration status?</h3>
      Irish Naturalisation and Immigration Service (INIS) handles renewals and changes. You should complete an application detailing your present status, the reason for the application, and any other supporting documentation before your current permit expires.
      `
    }
  ],
};

export default immigrationLaw;
