/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import bubbleImage from '../images/immigration-law-bubble.png';
import immigrationLawImage from '../images/immigration-law-full.jpg';
import immigrationLawImageFull from '../images/immigration-law-full.jpg';
import Icon from '../images/immigration-law.svg?react';

const TITLE = 'Immigration law';

const immigrationLaw: LibraryCategory = {
  name: TITLE,
  title: 'Introduction to Immigration Law in Australia',
  image: immigrationLawImage,
  previewImage: immigrationLawImageFull,
  shortDescription: 'Navigating family, sponsorships, visas, applications, and residency.',
  description:
    'Immigration law in Australia means the legal rules that guide people\'s entry into and exit from the country and their presence while in the country. It includes work visas, student visas, family visas, refugee and humanitarian visas, and the like, leading to permanent residency and citizenship. Immigration laws in Australia are dynamic and keep changing with changes in government policies, relations with other countries, and economic needs. The Department of Home Affairs mainly administers the system, and its decisions are usually informed by economic, security, and social considerations.',
  directoryTitle: 'Immigration law case studies',
  cases: cases.filter(
    (c) => c.category.toLocaleLowerCase() === TITLE.toLocaleLowerCase()
  ),
  Icon,
  bubbleImage,
  bodyText: [
    {
      header: 'Key legislation to consider',
      body: `
        <ul>
          <li><b>The Migration Act 1958</b> is the primary piece of legislation that regulates immigration in Australia and contains provisions on the type of visas that can be issued, the conditions for entry and exit, and the procedures for the deportation of unauthorised immigrants.</li>
          <li><b>The Migration Regulations 1994</b> regulations contain more specific details on visa classes, qualifications, and processes.</li>
          <li><b>The Australian Citizenship Act 2007</b> establishes the requirements for Australian citizenship, including the eligibility for naturalisation, the prerequisites, and the process of cancelling an Australian citizenship.</li>
          <li><b>Border Protection and Migration Reform</b> are part of the ongoing amendments and policies. They address border security, asylum processes, and offshore processing of refugees.</li>
          <li><b>The Skilled Migration Program</b> is a points-based system for qualifications, work experience, and English language proficiency that encourages skilled people to come to Australia.</li>
        </ul>`,
    },
    {
      header: 'Common questions asked about Immigration Law in Australia',
      body: `<h3>Which visa should I apply for?</h3>
      This depends on the purpose of your visit to Australia. Some of the most common are skilled work visas (for example, subclass 482 – Temporary Skill Shortage Visa), student visas (subclass 500), partner visas for married and de facto partners, and visitor visas. Family migration and humanitarian visas are also available.
      <br/>
      <br/>
      <h3>How do I obtain a Permanent Residency (PR)?</h3>
      There are several ways to obtain permanent residency, including the skilled migration program, employer-sponsored visas, family visas, and humanitarian programs. Some PR visas are points-based and have work experience and residency requirements.
      <br/>
      <br/>
      <h3>What can I do if my visa application is refused?</h3>
      If your application is refused, you may be able to appeal to the Administrative Appeals Tribunal (AAT) for a review of the decision. Sometimes, it is better to try again than to appeal. Legal advice can help determine the most appropriate strategy to be adopted.
      <br/>
      <br/>
      <h3>Can I work while waiting for my visa decision?</h3>
      Work rights are dependent on the visa subclass. Some bridging visas allow working rights, while others do not. Work rights are granted to asylum seekers under specific circumstances only.
      `
    }
  ],
};

export default immigrationLaw;
