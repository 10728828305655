/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import libraryLitigationImage from '../images/litigation-law-full.jpg';
import libraryLitigationImageFull from '../images/litigation-law-full.jpg';
import Icon from '../images/litigation-law.svg?react';
import bubbleImage from '../images/litigation-law-bubble.png';

const TITLE = 'Litigation';

const litigationLaw: LibraryCategory = {
  name: TITLE,
  title: 'Introduction to Litigation in the UK',
  image: libraryLitigationImage,
  previewImage: libraryLitigationImageFull,
  shortDescription: 'Litigation is resolving disputes through courts and legal proceedings.',
  description:
    'Litigation in the UK involves resolving civil disputes between two people, groups or businesses in courts. Common claims can include breaches of contract, negligence and personal injury. England and Wales share Civil Procedure Rules. However, Scotland and Northern Ireland differ slightly.',
  cases: cases.filter((c) => c.category.toLocaleLowerCase() === TITLE.toLocaleLowerCase()),
  Icon,
  bubbleImage,
  bodyText: [
    {
      header: 'Key legislation to consider',
      body: `
        <ul>
          <li><b>Civil Procedure Rules (CPR)</b> cover pre-action protocols, evidence, and court procedure.</li>
          <li><b>The Courts and Tribunals Structure</b> includes the County Court for less complex claims and the High Court for more complicated or higher-value disputes.</li>
          <li><b>The Limitation Act</b> specifies the timeframe within which individuals must bring various claims.</li>
        </ul>`,
    },
    {
      header: 'Common questions asked about Litigation Law in the UK',
      body: `<h3>How do I begin a court claim?</h3>
      To start a court claim, there are some pre-action steps. This includes sending a letter that explains the dispute. It is recommended to try to resolve the issue through negotiation or other methods and to only file a claim form in court if these efforts do not solve the issue. Then, serve the form on the defendant.
      <br/>
      <br/>
      <h3>Am I required to attempt mediation or Alternative Dispute Resolution (ADR)?</h3>
      Courts support using alternative dispute resolution, like mediation, to save time and money. Ignoring ADR can lead to higher costs, even if you win your case.
      <br/>
      <br/>
      <h3>What are the time limits for bringing a claim?</h3>
      Contract and negligence claims must be started within the limitation periods legislation sets. In contrast, personal injury claims typically have a shorter time frame, beginning on the date of the injury or when you first become aware of it.
      <br/>
      <br/>
      <h3>How are legal costs allocated if I win or lose?</h3>
      In most cases, the unsuccessful party must pay the successful party’s reasonable legal costs. However, more minor claims allow for the recovery of fewer fees.
      <br/>
      <br/>
      <h3>What evidence do I need for my case?</h3>
      Collect important documents like contracts, emails, and receipts. Also, get witness statements from people who know about the dispute. In more complex matters, expert reports—for example, from medical or technical professionals—may also be necessary.
      `
    }
  ],
};

export default litigationLaw;
