import commercialLaw from './commercial_law';
import employmentLaw from './employment_law';
import familyLaw from './family_law';
import immigrationLaw from './immigration_law';
import litigationLaw from './litigation';
import propertyLaw from './property_law';
import willsTrustAndProbateLaw from './wills_trust_and_probate';
import { LibraryCategory } from '../../../../types/library';

const libraryAssets: LibraryCategory[] = [
  commercialLaw,
  employmentLaw,
  familyLaw,
  immigrationLaw,
  litigationLaw,
  propertyLaw,
  willsTrustAndProbateLaw,
  //directory, // TODO: Add directory when there are cases
];

export default libraryAssets;

export const irelandDescription =
  // eslint-disable-next-line @stylistic/js/max-len
  'We have curated some common example legal cases created by our users from common areas of law. Browse the categories for your area of interest.';

