/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import libraryLitigationImage from '../images/litigation-law-full.jpg';
import libraryLitigationImageFull from '../images/litigation-law-full.jpg';
import Icon from '../images/litigation-law.svg?react';
import bubbleImage from '../images/litigation-law-bubble.png';

const TITLE = 'Litigation';

const litigationLaw: LibraryCategory = {
  name: TITLE,
  title: 'An Overview of Litigation in Australia',
  image: libraryLitigationImage,
  previewImage: libraryLitigationImageFull,
  shortDescription: 'Solving disputes through the courts and implementing legal rights.',
  description:
    'Litigation means the actual process of solving disputes through the courts and implementing legal rights. Although litigation can be slow and expensive, it is still an effective way of solving complex legal problems. However, with the ADR, finding more convenient and less costly solutions for some issues is sometimes possible.',
  directoryTitle: 'Litigation case studies',
  cases: cases.filter((c) => c.category.toLocaleLowerCase() === TITLE.toLocaleLowerCase()),
  Icon,
  bubbleImage,
  bodyText: [
    {
      header: 'Key legislation to consider',
      body: `
        <ul>
          <li>The Federal Court Act and State Civil Procedure Acts are the governing laws that regulate civil litigation and dispute resolution in Australia.</li>
          <li>The Rules of Court set the procedures and ensure that every litigation process is fair.</li>
          <li>Legal costs legislation governs the collection and distribution of costs about litigation.</li>
        </ul>`,
    },
    {
      header: 'Common questions asked about Litigation in Australia',
      body: `<h3>What is the difference between Litigation and Alternative Dispute Resolution (ADR)?</h3>
      Litigation is a structured legal procedure, while ADR, such as mediation and arbitration, entails less stringent and often faster means of solving conflicts.
      <br/>
      <br/>
      <h3>When are disputes allowed to be solved by litigation?</h3>
      Standard litigation is usually used in cases of legal complexity or where there is a question of a large sum of money.
      `
    }
  ],
};

export default litigationLaw;
