import React, { FC, useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import libraryAssets from '../assets';
import { convertToKebabCase } from '../../../utils/case-conversion';
import { Header } from '../../../components/header';
import { LibraryHero } from '../hero';
import { LibraryTextWrapper } from './text-wrapper';
import { NeedHelpCard } from '../../../components/need-help';
import { LibraryCarousel } from '../carousel';
import { Footer } from '../../../components/footer';
import style from './style.module.less';
import { getLibraryPathWithCountry } from '../../../utils/get-library-path-with-country';
import { LibraryCase as LC, LibraryCategory } from '../../../types/library';
import { LanguageCodes } from '../../../constants';
import { SupportedLanguages } from '../../../i18n';
import { listOfLibraryCountries } from '../assets';

export const LibraryCase: FC = () => {
  const { category, caseName } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // get the :country from the url
  const { languageCode } = useParams<{ languageCode: LanguageCodes }>();

  const currentCountryName = useMemo(
    () =>
      SupportedLanguages.find((country) => country.key === languageCode)
        ?.nativeName,
    [languageCode]
  );

  const currentCountry = useMemo(
    () =>
      listOfLibraryCountries.find(
        (country) => country.language === languageCode
      ),
    [languageCode]
  );

  const [libraryCategory, setLibraryCategory] = useState<LibraryCategory>(null);
  const [libraryCase, setLibraryCase] = useState<LC>(null);

  useEffect(() => {
    if (!currentCountry) {
      navigate(getLibraryPathWithCountry('', true));
    }

    // if no category or case found, redirect to library
    const foundCategory = libraryAssets.find(
      (c) => convertToKebabCase(c?.name) === category
    );
    if (foundCategory) {
      setLibraryCategory(foundCategory);
      const foundCase = foundCategory?.cases?.find(
        (c) => convertToKebabCase(c?.name) === caseName
      );
      if (foundCase) {
        setLibraryCase(foundCase);
      } else {
        // if no case found, redirect to library
        navigate(getLibraryPathWithCountry('', true));
      }
    } else {
      // if no category found, redirect to library
      navigate(getLibraryPathWithCountry('', true));
    }
  }, [category, caseName, libraryCategory, navigate, currentCountry]);

  if (!libraryCategory || !libraryCase) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{t('pages.library')}</title>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Article',
            mainEntityOfPage: {
              '@type': 'WebPage',
              '@id': `https://lawconnect.com/${languageCode}/library/${category}/${caseName}`,
            },
            headline: libraryCase.name,
            description: libraryCase.description,
            articleSection: libraryCategory.name,
            keywords: libraryCase.tags ? libraryCase.tags.join(',') : '',
            dateCreated: libraryCase.createdAt
              ? new Date(libraryCase.createdAt).toISOString()
              : new Date().toISOString(),
            datePublished: libraryCase.createdAt
              ? new Date(libraryCase.createdAt).toISOString()
              : new Date().toISOString(),
            articleBody: libraryCase.description,
            author: {
              '@type': 'Organization',
              name: 'LawConnect',
              url: 'https://lawconnect.com',
            },
            publisher: {
              '@type': 'Organization',
              name: 'LawConnect',
              logo: {
                '@type': 'ImageObject',
                url: 'https://lawconnect.com/logo.png',
              },
            },
            isAccessibleForFree: true,
            isPartOf: {
              '@type': 'WebSite',
              name: 'LawConnect Law Library',
              '@id': `https://lawconnect.com/${languageCode}/library/${category}`,
            },
            about: {
              '@type': 'Thing',
              name: libraryCase.name,
              description: libraryCase.description,
              category: libraryCase.category,
              matterType: libraryCase.matterType,
            },
            location: {
              '@type': 'Place',
              address: {
                '@type': 'PostalAddress',
                addressCountry: libraryCase.location?.country,
                addressRegion: libraryCase.location?.region,
              },
            },
            dateModified: libraryCase.createdAt,
            inLanguage: languageCode,
            logo: {
              '@type': 'ImageObject',
              url: 'https://lawconnect.com/favicon/favicon-32x32.png',
              width: '32',
              height: '32',
            },
          })}
        </script>

        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            'mainEntity': libraryCase.questions.map(q => {
              return {
                '@type': 'Question',
                'name': q.question,
                'acceptedAnswer': {
                  '@type': 'Answer',
                  'text': q.verifiedAnswer
                }
              };
            })
          })}
        </script>

      </Helmet>
      <Header />
      <div className={style.menuDivider} />
      <div className={style.marginWrapper}>
        <div className={style.heroDescriptionWrapper}>
          <LibraryHero
            breadcrumbs={[
              {
                name: t('pages.library'),
                url: `${getLibraryPathWithCountry()}`,
              },
              {
                name: `${currentCountryName}`,
                url: `${getLibraryPathWithCountry('', true)}`,
              },
              {
                name: libraryCategory.name,
                url: getLibraryPathWithCountry(
                  `/${convertToKebabCase(libraryCategory.name)}`,
                  true
                ),
              },
              { name: 'Case' },
            ]}
            description={libraryCase.name}
          />
          <LibraryTextWrapper case={libraryCase} />
        </div>
        <NeedHelpCard />
        <LibraryCarousel
          title={`Explore issues relating to ${libraryCategory.name}`}
          cases={libraryCategory.cases
            .filter((c) => c.name !== libraryCase.name)
            .slice(0, 10)}
          className={style.carouselContainer}
          viewAllCaseTitle={t('library.view-all-cases')}
        />
      </div>

      <Footer className={style.noFooterMargin} />
    </>
  );
};
