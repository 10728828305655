import React, { FC, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { listOfLibraryCountries } from '../assets';
import { convertToKebabCase } from '../../../utils/case-conversion';
import { Header } from '../../../components/header';
import { LibraryHero } from '../hero';
import { NeedHelpCard } from '../../../components/need-help';
import { LibraryCarousel } from '../carousel';
import { Footer } from '../../../components/footer';
import style from './style.module.less';
import { CategoryCards } from '../category-card';
import { 
  getLibraryPathWithCountry
} from '../../../utils/get-library-path-with-country';
import { Link } from 'react-router-dom';
import { LanguageCodes } from '../../../constants';
import ArrowRightIcon from '../../../assets/icons/arrow-right.svg?react';
import { SupportedLanguages } from '../../../i18n';

export const LibraryCategory: FC = () => {
  const { category } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // get the :languageCode from the url
  const { languageCode } = useParams<{ languageCode: LanguageCodes }>();

  const currentCountry = useMemo(() => {
    return listOfLibraryCountries.find(
      (c) => c.language === languageCode
    );
  }, [languageCode]);

  const currentCountryName = useMemo(
    () =>
      SupportedLanguages.find(
        (country) => country.key === languageCode
      )?.nativeName,
    [languageCode]
  );

  const libraryCategory = currentCountry?.content.find(
    (c) => convertToKebabCase(c?.name) === category
  );
  const extraCategories = currentCountry?.content.filter(
    (c) => convertToKebabCase(c?.name) !== category
  );

  const categoryCards = extraCategories
    ?.filter(category => !category.isDirectory)
    ?.sort(() => Math.random() - 0.5)
    ?.slice(0, 4);

  const CATEGORIES_PER_LIST = 6;
  const numLists = Math.ceil(extraCategories?.length / CATEGORIES_PER_LIST);
  const categoriesLists = Array.from({ length: numLists }, (_, i) =>
    extraCategories?.slice(
      i * CATEGORIES_PER_LIST,
      (i + 1) * CATEGORIES_PER_LIST
    )
  );

  useEffect(() => {
    // if no category or case found, redirect to library
    if (!libraryCategory) {
      navigate(getLibraryPathWithCountry('', true));
    }
  }, [libraryCategory, navigate]);

  if (!libraryCategory) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{t('pages.library')}</title>
      </Helmet>
      <Header />
      <div className={style.menuDivider} />
      <LibraryHero
        breadcrumbs={[
          {
            name: t('pages.library'),
            url: `${getLibraryPathWithCountry()}`,
          },
          {
            name: `${currentCountryName}`,
            url: `${getLibraryPathWithCountry('', true)}`,
          },
          {
            name: libraryCategory.name,
          },
        ]}
        description={libraryCategory.title}
      />
      <div className={style.descriptionContainer}>
        <div
          className={style.description}
          dangerouslySetInnerHTML={{ __html: libraryCategory.description }}
        />
      </div>
      {!libraryCategory.isDirectory ? (
        <>
          <div className={style.bodyContainer}>
            <div className={style.bodyImageContainer}>
              <img
                key={libraryCategory.bubbleImage}
                src={libraryCategory.bubbleImage}
                alt={libraryCategory.name}
                className={style.bodyImage}
                loading="lazy"
              />
            </div>
            <div className={style.bodyTextContainer}>
              {libraryCategory.bodyText.map((text, index) => (
                <>
                  <div key={index} className={style.bodyText}>
                    <h2 className={style.bodyTextTitle}>{text.header}</h2>
                    <div
                      className={style.bodyTextDescription}
                      dangerouslySetInnerHTML={{ __html: text.body }}
                    />
                    
                  </div>
                  {index !== libraryCategory.bodyText.length - 1 && (
                    <NeedHelpCard
                      classNames={{
                        container: style.needHelpBodyTextContainer,
                      }}
                    />
                  )}
                </>
              ))}
            </div>
          </div>

          {libraryCategory.cases.length > 0 && (
            <LibraryCarousel
              title={`Explore issues relating to ${libraryCategory.name}`}
              cases={libraryCategory.cases.slice(0, 10)}
              titleClassName={style.carouselTitle}
              viewAllCaseTitle={t('library.view-all-cases')}
              className={style.carousel}
            />
          )}
          <NeedHelpCard classNames={{ container: style.needHelpContainer }} />

          <div className={style.moreCategories}>
            <div className={style.moreCategoriesTitle}>
              {t('library.category.explore-more')}
            </div>
            <CategoryCards
              categories={categoryCards}
              classNames={{
                containerClassName: style.categoryCardsContainer,
                titleClassName: style.categoryCardTitle,
                iconContainerClassName: style.categoryCardIconContainer,
                iconClassName: style.categoryCardIcon,
              }}
            />
          </div>
        </>
      ) : (
        <div className={style.directoryContainer}>
          <div className={style.directoryContainerInner}>
            {categoriesLists.map((categories, index) => (
              <div className={style.directoryCategories} key={index}>
                {categories.map((category) => (
                  <div
                    className={style.categoryList}
                    key={convertToKebabCase(category.name)}
                  >
                    {category.title ? (
                      <Link
                        to={`/${languageCode}/library/${convertToKebabCase(
                          category.name
                        )}`}
                        className={style.categoryListNameLink}
                      >
                        {category.name}
                        <ArrowRightIcon className={style.categoryListArrow} />
                      </Link>
                    ) : (
                      <div className={style.categoryListName}>
                        {category.name}
                      </div>
                    )}
                    <div className={style.categoryCasesList}>
                      {category.cases.slice(0, 9).map((caseItem) => (
                        <Link
                          to={`/${languageCode}/library/${convertToKebabCase(
                            category.name
                          )}/${convertToKebabCase(caseItem.name)}`}
                          className={style.categoryCase}
                          key={convertToKebabCase(caseItem.name)}
                        >
                          {caseItem.name}
                        </Link>
                      ))}
                      {/* Add a 'View all' link here if there are more than 10 cases */}
                      {category.cases.length > 9 && (
                        <Link
                          to={`/${languageCode}/library/${convertToKebabCase(
                            category.name)}/directory`}
                          className={style.viewAll}
                        >
                          {t('library.view-all')}
                          <ArrowRightIcon className={style.viewAllArrow} />
                        </Link>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <NeedHelpCard
            classNames={{ container: style.needHelpContainer }}
          />
        </div>
      )}

      <Footer className={style.noFooterMargin} />
    </>
  );
};
