/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import bubbleImage from '../images/immigration-law-bubble.png';
import immigrationLawImage from '../images/immigration-law-full.jpg';
import immigrationLawImageFull from '../images/immigration-law-full.jpg';
import Icon from '../images/immigration-law.svg?react';

const TITLE = 'Immigration law';

const immigrationLaw: LibraryCategory = {
  name: TITLE,
  title: 'Introduction to Immigration Law in the UK',
  image: immigrationLawImage,
  previewImage: immigrationLawImageFull,
  shortDescription: 'Navigating family, sponsorships, visas, applications, and residency.',
  description:
    'The study of immigration law in the UK covers the rules for entering, staying, and leaving the country. It covers topics like work visas, student visas, family visas, asylum, refugee status, and British nationality. The rules and regulations are not always easy to understand. They can change often based on politics and international relations.',
  cases: cases.filter(
    (c) => c.category.toLocaleLowerCase() === TITLE.toLocaleLowerCase()
  ),
  Icon,
  bubbleImage,
  bodyText: [
    {
      header: 'Key legislation to consider',
      body: `
        <ul>
          <li><b>The Immigration Act 1971</b> is the primary piece of legislation that provides the basics on entry, stay, and departure.</li>
          <li><b>The Immigration, Asylum and Nationality Act 2006</b> has more recent rules. These rules aim to stop employers from hiring illegal workers. They use the Certificate of Employer’s Tone and Compliance (TECTA). Employers face penalties if they hire foreigners without work permits. The effects, challenges, and possible solutions of illegal working in the UK are also discussed.</li>
          <li><b>The Borders, Citizenship and Immigration Act 2009</b> addresses border control, nationality, citizenship, and enforcement powers.</li>
          <li><b>The UK Points-Based System</b> is a set of rules exists for non-UK nationals. These rules explain how they can come to the UK to work or study. The system uses points based on skills, job offers, and language skills.</li>
        </ul>`,
    },
    {
      header: 'Common questions asked about Immigration Law in the UK',
      body: `<h3>Which visa route should I apply for?</h3>
      It depends on the purpose of your visit to the UK: work, study, join a family member, etc. The points-based system corresponds to the Tier categories, such as the Skilled Worker and Student visas. Family visas enable spouses, partners and dependents to come and join UK residents.
      <br/>
      <br/>
      <h3>How do I obtain Indefinite Leave to Remain (ILR)?</h3>
      You usually need to live in the UK for a qualifying period. This period is typically 5 years on a valid visa. You must also meet the requirements for residency, English language, and the Life in the UK test.
      <br/>
      <br/>
      <h3>What options do I have if the authorities refuse my visa application?</h3>
      It depends on the cause of the refusal, you may have the right to appeal, apply for an administrative review or re-apply. Consulting with a lawyer to learn the best course of action in this case is important.
      <br/>
      <br/>
      <h3>Can I work while waiting for my asylum decision?</h3>
      Asylum seekers usually cannot work. They can only work if they have waited over 12 months for the first decision. The delay must not be their fault. Nevertheless, employers can limit work options.
      `
    }
  ],
};

export default immigrationLaw;
