import commercialLaw from './commercial_law';
import employmentLaw from './employment_law';
import familyLaw from './family_law';
import immigrationLaw from './immigration_law';
import litigationLaw from './litigation';
import propertyLaw from './property_law';
import willsTrustAndProbateLaw from './wills_trust_and_probate';
import { LibraryCategory } from '../../../../types/library';

const libraryAssets: LibraryCategory[] = [
  commercialLaw,
  employmentLaw,
  familyLaw,
  immigrationLaw,
  litigationLaw,
  propertyLaw,
  willsTrustAndProbateLaw,
  //directory, // TODO: Add directory when there are cases
];

export default libraryAssets;

export const ukDescription =
  // eslint-disable-next-line @stylistic/js/max-len
  'We have curated some common example legal cases created by our users from common areas of law. Browse the categories for your area of interest.';

export const homeLibraryAssets: {
  name: string;
  image: string;
  isDirectory?: boolean;
}[] = [
  {
    name: commercialLaw.name,
    image: commercialLaw.image,
    isDirectory: true,
  },
  {
    name: employmentLaw.name,
    image: employmentLaw.image,
  },
  {
    name: familyLaw.name,
    image: familyLaw.image,
  },
  {
    name: immigrationLaw.name,
    image: immigrationLaw.image,
  },
  {
    name: litigationLaw.name,
    image: litigationLaw.image,
  },
  {
    name: propertyLaw.name,
    image: propertyLaw.image,
  },
  {
    name: willsTrustAndProbateLaw.name,
    image: willsTrustAndProbateLaw.image,
  },
];
