/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import bubbleImage from '../images/real-estate-bubble.png';
import realEstateImage from '../images/real-estate.jpg';
import realEstateImageFull from '../images/real-estate-full.jpg';
import Icon from '../images/real-estate.svg?react';

const TITLE = 'Real estate and property law';

const realEstate: LibraryCategory = {
  name: TITLE,
  title: 'Introduction to Real estate law in Australia',
  image: realEstateImage,
  previewImage: realEstateImageFull,
  shortDescription:
    'Proactive measures to prevent disputes or prepare for action.',
  description:
    'Real estate law governs the rights and obligations of property owners, buyers, and tenants, ensuring fair dealings and protecting interests in real property. It encompasses a wide range of legal issues, from property transfers and leasing agreements to land use regulations and zoning laws.',
  directoryTitle: 'Real estate law case studies',
  cases: cases.filter((c) => c.category.toLocaleLowerCase() === TITLE.toLocaleLowerCase()),
  Icon,
  bubbleImage,
  bodyText: [
    {
      header: 'Key legislation to consider',
      body: `
        <ul>
          <li>The Residential Tenancies Acts define the relationship between landlords and tenants in each state or territory. For example, New South Wales has the Residential Tenancies Act 1997.</li>
          <li>The Retail Leases Act applies in most states for commercial properties. It defines specific duties and rights for retail lease agreements.</li>
          <li>Lease agreements follow contract law principles. These principles help with issues in lease law, like understanding leases and breaches of lease terms.</li>
        </ul>`,
    },
    {
      header: 'Common questions asked about Lease Law in Australia',
      body: `<h3>What are the most common causes of lease disputes?</h3>
      The most common causes of lease disputes are ambiguous lease terms, unauthorised use of the property and arguments concerning repair and maintenance.
      <br/>
      <br/>
      <h3>When should formal legal action be taken?</h3>
      Legal action is usually the last resort when all other means, including the landlord and tenant’s negotiation or mediation, have been exhausted.
      <br/>
      <br/>
      <h3>How can disputes be prevented in the first place?</h3>
      This can be achieved by having well-detailed and clear lease agreements and an open communication channel between the landlord and the tenant.
      `
    }
  ],
};

export default realEstate;
