/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import bubbleImage from '../images/family-law-bubble.png';
import familyImage from '../images/family.jpg';
import familyImageFull from '../images/family-full.jpg';
import Icon from '../images/family-law.svg?react';

const TITLE = 'Family law';

const familyLaw: LibraryCategory = {
  name: TITLE,
  title: 'Introduction to Family Law in Australia',
  image: familyImage,
  previewImage: familyImageFull,
  shortDescription:
    'Navigating family law in Australia can be complex and challenging.',
  description:
    'Family law in Australia addresses legal issues arising from family relationships, including marriage, divorce, child custody, and property settlements. The legal framework, established mainly by the Family Law Act 1975, focuses on resolving disputes fairly and prioritises children\'s best interests in any decision-making process.',
  directoryTitle: 'Family law case studies',
  cases: cases.filter((c) => c.category.toLocaleLowerCase() === TITLE.toLocaleLowerCase()),
  Icon,
  bubbleImage,
  bodyText: [
    {
      header: 'Key legislation to consider',
      body: `
        <ul>
          <li>The Family Law Act 1975 provides the primary legal framework for handling divorce, custody, and property settlement matters.</li>
          <li>State-based matrimonial legislation may complement federal law in some regions of family law.</li>
          <li>Child Support Guidelines regulate parents' financial responsibilities to ensure children's well-being.</li>
        </ul>`,
    },
    {
      header: 'Common questions asked about Family Law in Australia',
      body: `<h3>How is child custody determined?</h3>
      Custody decisions are based on the child's best interests, with courts often favouring shared custody arrangements when appropriate.
      <br/>
      <br/>
      <h3>What is involved in the divorce process?</h3>
      The process includes meeting eligibility criteria, filing the proper application with the Federal Circuit or Family Court, and making suitable arrangements for any children involved.
      <br/>
      <br/>
      <h3>How are property settlements resolved?</h3>
      Property and asset division is typically negotiated between the parties, with mediation or court intervention used when disputes cannot be resolved independently.
      `
    }
  ],
};

export default familyLaw;
