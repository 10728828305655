/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import bubbleImage from '../images/estates-probate-bubble.png';
import estatesImage from '../images/estates.jpg';
import estatesImageFull from '../images/estates-full.jpg';
import Icon from '../images/estates-probate.svg?react';

const TITLE = 'Estate and probate law';

const estatesAndProbates: LibraryCategory = {
  name: TITLE,
  title: 'Introduction to Estates and Probate in Australia',
  image: estatesImage,
  previewImage: estatesImageFull,
  shortDescription: 'Understanding estate planning and probate processes.',
  description:
    'Estate planning in Australia means arranging the transmission of wealth at death to ensure that one’s wishes are respected and that dependents are provided for. Probate is the legal procedure that confirms the existence of a will and allows the executor to control and disburse the estate. It is essential to point out that effective estate planning is about more than just a will; it also involves trusts, superannuation nominations, powers of attorney, and advance care directives.',
  directoryTitle: 'Estates and probate case studies',
  cases: cases.filter(
    (c) => c.category.toLocaleLowerCase() === TITLE.toLocaleLowerCase()
  ),
  Icon,
  bubbleImage,
  bodyText: [
    {
      header: 'Key legislation to consider',
      body: `
        <ul>
          <li>The Succession Acts differ from one state to another (for instance, the Succession Act 2006 in Queensland) and cover wills, estates and probate.</li>
          <li>Wills legislation specifies the formalities for making, signing and validating a will.</li>
          <li>Probate laws and procedures are the rules on proving the validity of a will and how to dispose of an estate properly.</li>
        </ul>`,
    },
    {
      header: 'Common questions asked about Estates and Probate in Australia',
      body: `<h3>What is the difference between a will and a complete estate plan?</h3>
      A will is a legal document that directs how your property will be distributed after death. At the same time, an estate plan is all the documents and procedures required for your financial and legal affairs during your lifetime and after your death. It also includes planning for the distribution of your assets and final arrangements.
      <br/>
      <br/>
      <h3>When is probate required?</h3>
      Probate is necessary for larger or more complex estates to prove the will and allow the executor to deal with the estate.
      <br/>
      <br/>
      <h3>How can I protect and update my estate plan?</h3>
      Checks and changes should also be done frequently, and it is advisable to seek a lawyer's advice to ensure that the estate plan is up-to-date and binding.
      `
    }
  ],
};

export default estatesAndProbates;
