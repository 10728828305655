/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import bubbleImage from '../images/real-estate-bubble.png';
import libraryPropertyLawImage from '../images/real-estate.jpg';
import libraryPropertyLawImageFull from '../images/real-estate-full.jpg';
import Icon from '../images/real-estate.svg?react';

const TITLE = 'Property Law';

const propertyLaw: LibraryCategory = {
  name: TITLE,
  title: 'Introduction to Property Law in Ireland',
  image: libraryPropertyLawImage,
  previewImage: libraryPropertyLawImageFull,
  shortDescription: 'Property law covers the rights to use and own land and buildings and how they can be transferred',
  description:
    'Property law in Ireland governs the ownership, use, and transfer of land and buildings. It covers freehold and leasehold interests, landlord-tenant relationships, the conveyancing process, planning permissions, and dispute resolution. Property transactions and tenancies are regulated by legislation to ensure clarity and fairness in property dealings.',
  cases: cases.filter((c) => c.category.toLocaleLowerCase() === TITLE.toLocaleLowerCase()),
  Icon,
  bubbleImage,
  bodyText: [
    {
      header: 'Key legislation to consider',
      body: `
        <ul>
          <li><b>The Land and Conveyancing Law Reform Act 2009</b> modernises property law, setting rules on property ownership, transfers, and rights.</li>
          <li><b>The Registration of Title Act 1964</b> relates to how property ownership and interests are recorded in the Land Registry and Registry of Deeds, ensuring clarity in land ownership.</li>
          <li><b>The Residential Tenancies Acts 2004–2021</b> regulate private and social housing tenancies, including tenant rights, rent controls, and dispute resolution.</li>
          <li><b>The Landlord and Tenant Acts</b> outline obligations and rights in commercial and residential leases. The Planning and Development Acts 2000–2021 set out planning permission requirements and building regulations.</li>
          <li><b>The Stamp Duties Consolidation Act 1999</b> provides the legal framework for stamp duty on property transactions.</li>
        </ul>`,
    },
    {
      header: 'Common questions about Property Law in Ireland',
      body: `<h3>What are my rights and obligations as a landlord or tenant?</h3>
      Landlords must provide a safe, habitable property, register tenancies with the <b>Residential Tenancies Board (RTB)</b>, and follow legal procedures for rent increases and evictions. Tenants must pay rent on time, maintain the property, and adhere to the lease agreement. The RTB resolves disputes between landlords and tenants.
      <br/>
      <br/>
      <h3>How do I buy or sell residential property in Ireland?</h3>
      The conveyancing process involves hiring a solicitor to conduct property searches, draft contracts, and oversee the transfer of ownership. Buyers typically secure mortgage approval before exchanging contracts, making the purchase legally binding. Once funds are transferred, the sale is completed, and the property is registered with the <b>Land Registry</b>.
      <br/>
      <br/>
      <h3>When do I need planning permission?</h3>
      Planning permission is required for significant building works, extensions, and changes in property use. Some minor works, such as small extensions or renovations, may be exempt under <b>exempted development rules</b>. Applications are submitted to the local authority, which assesses compliance with planning laws.
      <br/>
      <br/>
      <h3>How do I resolve boundary disputes?</h3>
      Reviewing title deeds, Land Registry maps, and professional land surveys can often resolve boundary disputes. Mediation is encouraged as the first step, but legal action through the courts may be necessary if no agreement is reached.
      `
    }
  ],
};

export default propertyLaw;
