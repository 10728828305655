/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import bubbleImage from '../images/estates-probate-bubble.png';
import libraryWillsTrustAndProbateImage from '../images/estates.jpg';
import libraryWillsTrustAndProbateImageFull from '../images/estates-full.jpg';
import Icon from '../images/estates-probate.svg?react';

const TITLE = 'Wills, Trusts, and Probate';

const willsTrustAndProbateLaw: LibraryCategory = {
  name: TITLE,
  title: 'Introduction to Wills, Trusts, and Probate in Ireland',
  image: libraryWillsTrustAndProbateImage,
  previewImage: libraryWillsTrustAndProbateImageFull,
  shortDescription: 'Wills, trusts, and probate cover the legal process of managing and distributing assets after death.',
  description:
    'The Irish laws that regulate  Wills, Trusts, & Probate manage how assets pass to beneficiaries after death while handling trust creation and administration of estates. The legal system provides a path for individuals to define their post-death distribution of assets while maintaining that beneficiaries receive what they are due in an organised way. In Ireland, executors, trustees, and beneficiaries must follow specific processes and fulfil legal requirements to pay estate debts and taxes.',
  cases: cases.filter((c) => c.category.toLocaleLowerCase() === TITLE.toLocaleLowerCase()),
  Icon,
  bubbleImage,
  bodyText: [
    {
      header: 'Key legislation to consider',
      body: `
        <ul>
          <li><b>The Succession Act 1965</b> is the primary legislation that regulates the preparation and validity of wills and the distribution of assets when no will exists.</li>
          <li><b>The Wills Act 1837 (as amended)</b> are the formal guidelines for will-making according to this statute.</li>
          <li><b>The Trustee Act 1893</b> defines trustees' powers, responsibilities, legal consequences, and how they must handle trust assets.</li>
          <li><b>The Taxes Consolidation Act 1997</b> specifies Capital Acquisitions  Tax requirements for beneficiaries.</li>
        </ul>`,
    },
    {
      header: 'Common questions about Wills, Trust, & Probate Law in Ireland',
      body: `<h3>What is a will, and what is the need to create one?</h3>
      A will is a written document describing how assets will be given out after the owner's death. A will provides direction for the management of your estate, which will prevent problems during administration and minimise potential family conflicts. It must be signed by two witnesses who also sign it, and comply with the Sucession Act 1965.
      <br/>
      <br/>
      <h3>How do I  challenge a will?</h3>
      A will can be challenged on three grounds: lack of testamentary capacity of the will-maker, undue influence over the will-maker and non-compliance with the formalities required for a will. Challenges usually occur in court; you should consult a legal professional immediately.
      <br/>
      <br/>
      <h3>What is probate, and how do I obtain it?</h3>
      Probate refers to verifying a will's authenticity and appointing an executor to manage the estate. The executor obtains probate through submission of the original will along with a balance sheet and other required documents to the Probate Office or District Probate Registry.
      `
    }
  ],
};

export default willsTrustAndProbateLaw;
