import {
  LanguageCodes,
  LibraryCountry,
  LibraryCountryCode,
} from '../../../constants';
import { LibraryCategory } from '../../../types/library';
import australiaContent, { australiaDescription } from './australia';
import irelandContent, { irelandDescription } from './ireland';
import ukContent, { ukDescription } from './united-kingdom';

export default australiaContent;

export interface LibraryCountryData {
  country: LibraryCountry;
  language: LanguageCodes;
  code: LibraryCountryCode;
  content: LibraryCategory[];
  description: string;
}

export const listOfLibraryCountries: LibraryCountryData[] = [
  {
    country: LibraryCountry.Australia,
    language: LanguageCodes.Australia,
    code: LibraryCountryCode.Australia,
    content: australiaContent,
    description: australiaDescription,
  },
  {
    country: LibraryCountry.Ireland,
    language: LanguageCodes.Ireland,
    code: LibraryCountryCode.Ireland,
    content: irelandContent,
    description: irelandDescription,
  },
  {
    country: LibraryCountry.UnitedKingdom,
    language: LanguageCodes.UnitedKingdom,
    code: LibraryCountryCode.UnitedKingdom,
    content: ukContent,
    description: ukDescription,
  },
];
