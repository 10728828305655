/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import bubbleImage from '../images/estates-probate-bubble.png';
import libraryWillsTrustAndProbateImage from '../images/estates.jpg';
import libraryWillsTrustAndProbateImageFull from '../images/estates-full.jpg';
import Icon from '../images/estates-probate.svg?react';

const TITLE = 'Wills, Trusts, and Probate';

const willsTrustAndProbateLaw: LibraryCategory = {
  name: TITLE,
  title: 'Introduction to Wills, Trusts, and Probate in the UK',
  image: libraryWillsTrustAndProbateImage,
  previewImage: libraryWillsTrustAndProbateImageFull,
  shortDescription: 'Wills, trusts, and probate cover the legal process of managing and distributing assets after death.',
  description:
    'This area of law governs how an individual administers and distributes their estate after death. A valid will shows what the deceased wanted. Trusts can protect or manage assets for beneficiaries. Probate confirms who can manage the estate.',
  cases: cases.filter((c) => c.category.toLocaleLowerCase() === TITLE.toLocaleLowerCase()),
  Icon,
  bubbleImage,
  bodyText: [
    {
      header: 'Key legislation to consider',
      body: `
        <ul>
          <li><b>The Wills Act</b> sets out the requirements for creating and validating wills.</li>
          <li><b>The Administration of Estates Act</b> lays out the framework for distributing property if no valid will exists.</li>
          <li><b>The Trustee Act</b> details the powers and duties of trustees in managing assets held in trust.</li>
        </ul>`,
    },
    {
      header: 'Common questions asked about Wills, Trust, & Probate Law in the UK',
      body: `<h3>How do I create a valid will?</h3>
      A valid will must be in writing and signed by the person making it in the presence of independent witnesses, who also sign. You can make any significant changes via a codicil (a legal document that amends a will) or by drafting a new will.
      <br/>
      <br/>
      <h3>What happens if I do not have a will?</h3>
      Dying intestate means that the law decides how to distribute your estate. It usually gives priority to spouses, civil partners, and children. Unmarried partners usually do not inherit automatically. This can cause problems unless the will names them or they have other legal rights.
      <br/>
      <br/>
      <h3>What is probate, and when is it required?</h3>
      Probate is the official authority for executors or administrators to handle a deceased person’s estate.  It is often needed if the estate is large or if some assets need proof of authority before transfer.
      <br/>
      <br/>
      <h3>What is Inheritance Tax (IHT)?</h3>
      Inheritance tax refers to the tax on the estate of someone who has died. It includes all property, possessions and money.
      <br/>
      <br/>
      <h3>Is Inheritance Tax payable?</h3>
      The tax authorities may tax estates that exceed the nil-rate band of £325,000 at the standard rate of 40% on the amount above that threshold. Certain exemptions such as transfers to a spouse or gifts to charities can reduce any tax liability.
      <br/>
      <br/>
      <h3>When would I consider setting up a trust?</h3>
      Trusts can help with tax planning. They can also provide for children or vulnerable beneficiaries. Trusts can control how assets are used. There are different types of trusts, like discretionary, bare, and life interest trusts. Each type has its own tax and legal implications.
      `
    }
  ],
};

export default willsTrustAndProbateLaw;
