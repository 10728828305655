/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import bubbleImage from '../images/real-estate-bubble.png';
import libraryPropertyLawImage from '../images/real-estate.jpg';
import libraryPropertyLawImageFull from '../images/real-estate-full.jpg';
import Icon from '../images/real-estate.svg?react';

const TITLE = 'Property Law';

const propertyLaw: LibraryCategory = {
  name: TITLE,
  title: 'Introduction to Property Law in the UK',
  image: libraryPropertyLawImage,
  previewImage: libraryPropertyLawImageFull,
  shortDescription: 'Property law covers the rights to use and own land and buildings and how they can be transferred',
  description:
    'Property law covers the rights to use and own land and buildings and how they can be transferred. Both freehold and leasehold interests are included. This covers landlord and tenant relationships. It also includes the conveyancing process, planning permissions, and other disputes.',
  cases: cases.filter((c) => c.category.toLocaleLowerCase() === TITLE.toLocaleLowerCase()),
  Icon,
  bubbleImage,
  bodyText: [
    {
      header: 'Key legislation to consider',
      body: `
        <ul>
          <li><b>The Law of Property Act</b> establishes basic rules on the ownership and movement of property.</li>
          <li><b>The Land Registration Act</b> explains how to register and document ownership and interests. This helps reduce confusion about who owns the title.</li>
          <li><b>The Housing Act</b> regulates standards of residential tenancies, including assured and shorthold (AST) tenancies.</li>
          <li><b>Landlord & Tenant Acts</b> specify the obligations and rights in commercial and residential tenancies.</li>
        </ul>`,
    },
    {
      header: 'Common questions asked about Property Law in the UK',
      body: `<h3>What are my rights and obligations as a landlord or tenant?</h3>
      Residential tenancies should follow fair agreements and legal eviction rules. The landlord must ensure the property is safe and livable. The tenant must follow the tenancy agreement, pay rent on time, and take care of the property.
      <br/>
      <br/>
      <h3>How do I buy or sell residential property in the UK?</h3>
      The conveyancing process involves making property inquiries, preparing and agreeing on the contracts and transferring funds. Home buyers usually get a mortgage or another type of funding. Then, they exchange contracts, which makes the purchase legally binding.
      <br/>
      <br/>
      <h3>When do I need planning permission?</h3>
      Major building work or use changes typically require approval from the local authority. Some small changes or home improvements may qualify as Permitted Development. This means they do not need formal permission.
      <br/>
      <br/>
      <h3>How do I resolve boundary disputes?</h3>
      Gathering evidence, including title deeds, Land Registry documents, and professional surveys, helps. Mediation is usually the first step to solve these disputes. Court action is seen as a last resort if talks fail.
      <br/>
      <br/>
      <h3>What is Stamp Duty Land Tax (SDLT)?</h3>
      SDLT applies to property purchases above specified thresholds. The rates depend on the property's value, whether residential or commercial, and whether it is an additional purchase.
      `
    }
  ],
};

export default propertyLaw;
