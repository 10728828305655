/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import bubbleImage from '../images/family-law-bubble.png';
import familyImage from '../images/family.jpg';
import familyImageFull from '../images/family-full.jpg';
import Icon from '../images/family-law.svg?react';

const TITLE = 'Family law';

const familyLaw: LibraryCategory = {
  name: TITLE,
  title: 'Introduction to Family Law in the UK',
  image: familyImage,
  previewImage: familyImageFull,
  shortDescription:
    'Navigating family law in the UK can be complex and challenging.',
  description:
    'Family law in the UK deals with personal relationships. This includes marriage, civil partnerships, divorce, child arrangements, adoption, and protection against domestic abuse. It aims to protect vulnerable people, especially children. It also provides a way to resolve disputes fairly and evenly.',
  directoryTitle: 'Family law case studies',
  cases: cases.filter((c) => c.category.toLocaleLowerCase() === TITLE.toLocaleLowerCase()),
  Icon,
  bubbleImage,
  bodyText: [
    {
      header: 'Key legislation to consider',
      body: `
        <ul>
          <li><b>The Matrimonial Causes Act 1973</b> sets the rules for divorce. It covers financial matters and other disputes between husbands and wives.</li>
          <li><b>The Children Act 1989</b> focuses on the welfare of children. It sets out provisions for parental responsibility, child custody (including residence and contact), and the child protection system.</li>
          <li><b>The Family Law Act 1996</b> covers divorce proceedings, mediation and protection from domestic violence.</li>
          <li><b>The Civil Partnership Act 2004</b> allows same-sex couples to form a civil partnership. This gives them the same rights and responsibilities as marriage.</li>
        </ul>`,
    },
    {
      header: 'Common questions asked about Family Law in the UK',
      body: `<h3>How does the divorce process work?</h3>
      In England and Wales, you can get a divorce if your marriage has ended for good. You can file online now. The process begins with a conditional order, called Decree Nisi. Next, it moves to the final order, known as Decree Absolute. Scotland has a similar process, but with some differences in the steps.
      <br/>
      <br/>
      <h3>What is the difference between child custody and parental responsibility?</h3>
      Parental responsibility means the rights and duties a parent has for their child. This includes taking care of their education and medical needs. Child custody, also known as child contact, refers to where a child lives. It also decides how often a child spends time with each parent.
      <br/>
      <br/>
      <h3>How are financial settlements determined in a divorce?</h3>
      The court will look at several factors. These include the needs, income, and financial resources of both spouses. It will also consider the standard of living the children had. The length of the marriage is important too. Lastly, the roles of each spouse in the marriage will be reviewed. It is possible to reach an agreement without help. Mediation can also assist, but if that fails, the court will step in.
      <br/>
      <br/>
      <h3>How is child maintenance calculated?</h3>
      The Child Maintenance Service calculates payments based on the paying parent’s weekly income. They also consider the number of children and other factors. These factors include shared care and new family responsibilities.
      `
    }
  ],
};

export default familyLaw;
