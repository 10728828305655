/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import libraryCatDirectory from '../images/library-cat-directory.jpg';
import Icon from '../../../../assets/icons/arrow-right.svg?react';

const TITLE = 'Directory';

const directory: LibraryCategory = {
  name: TITLE,
  title: 'Browse cases',
  isDirectory: true,
  image: libraryCatDirectory,
  previewImage: libraryCatDirectory,
  shortDescription:
    'View our entire directory of cases from all subject matters.',
  description:
    'We have curated some common example legal cases created by our users from common areas of law. Browse the categories for your area of interest.',
  cases: cases.filter((c) => c.category.toLocaleLowerCase() === TITLE.toLocaleLowerCase()),
  Icon,
  bodyText: [],
};

export default directory;
