export const convertToSnakeCase = (str: string) => { 
  // convert camelCase, PascalCase, words with spaces, and kebab-case to snake_case
  return str && str
    .replace(/([a-z0-9])([A-Z])/g, '$1_$2')
    .replace(/([a-zA-Z0-9])\s([a-zA-Z0-9])/g, '$1_$2')
    .replace(/-+/g, '_')
    .toLowerCase();
};

export const convertToKebabCase = (str: string) => {
  return str && str
    .replace(/([a-z0-9])([A-Z])/g, '$1-$2')
    .replace(/([a-zA-Z0-9])\s([a-zA-Z0-9])/g, '$1-$2')
    .replace(/_+/g, '-')
    .replace(/, /g, '-')
    .toLowerCase();
};
