/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import libraryEmploymentImage from '../images/employment.jpg';
import libraryEmploymentImageFull from '../images/employment-full.jpg';

import Icon from '../images/employment-law.svg?react';
import bubbleImage from '../images/employment-law-bubble.png';

const TITLE = 'Employment law';

const employmentLaw: LibraryCategory = {
  name: TITLE,
  title: 'Introduction to Employment Law in the UK',
  image: libraryEmploymentImage,
  previewImage: libraryEmploymentImageFull,
  shortDescription: 'Fair treatment for both employers and employees.',
  description:
    `The UK employment law provides general employment regulations and legal information for employers and employees. It protects against discrimination, guarantees equal opportunities and sets minimum wages and working time regulations.
    Employment law is based on several laws, including the Employment Rights Act 1996 and the Equality Act 2010. Other health and safety rules also exist. Four main types of standard employment contracts exist: permanent, fixed-term, zero-hour, and part-time. Each of these contracts contains different rights and obligations.  
    `,
  cases: cases.filter((c) => c.category.toLocaleLowerCase() === TITLE.toLocaleLowerCase()),
  Icon,
  bubbleImage,
  bodyText: [
    {
      header: 'Key legislation to consider',
      body: `
        <ul>
          <li><b>The Employment Rights Act 1996 (ERA 1996)</b> covers unfair dismissal, redundancy and notice periods, among other rights. </li>
          <li><b>The Equality Act 2010</b> protects workers from discrimination. This protection covers specific 'protected characteristics.' These include age, disability, gender reassignment, marriage or civil partnership, pregnancy or maternity, race, religion or belief, sex, and sexual orientation. </li>
          <li><b>The Working Time Regulations 1998</b> regulates maximum weekly working time, rest breaks, and paid annual leave. </li>
          <li><b>The Health and Safety at Work Act 1974</b> sets rules for employers. It requires them to keep workers safe at their jobs.</li>
        </ul>`,
    },
    {
      header: 'Common questions asked about Employment Law in the UK',
      body: `<h3>What is considered unfair dismissal, and how can I pursue a claim?</h3>
      Unfair dismissal happens when an employer does not have a valid reason to fire someone. It also occurs if they do not follow a fair process. Usually, two years’ service is required before an employee can claim, except for unfair dismissal, which is specified (e.g. whistleblowing). You should first try your employer’s grievance or appeal process; if that does not work, you can go to an Employment Tribunal.
      <br/>
      <br/>
      <h3>What rights do I have if my employer makes me redundant?</h3>
      Redundancy arises when a business closes or specific roles are no longer required. Entitled redundancy pay, notice and proper consultation are only available to eligible employees. A weak claim for unfair dismissal is possible if the process is unjust.
      <br/>
      <br/>
      <h3>Am I entitled to flexible working or family leave?</h3>
      Workers who have completed a minimum of 26 weeks of service are eligible to request flexible working arrangements. You have rights for Maternity, Paternity, Adoption, and Shared Parental Leave based on your eligibility. Each of these offers different protections.
      <br/>
      <br/>
      <h3>What should I do if I have a dispute over my employment contract?</h3>
      Contract disputes often involve pay, hours, duties, or restrictive covenants. It’s best to try resolving issues through internal channels or ACAS conciliation. You can also make claims through an Employment Tribunal.
      `
    }
  ],
};

export default employmentLaw;
